/* eslint-disable @typescript-eslint/no-explicit-any */
interface UtilityActionI {
  type:
    | 'showToast'
    | 'hideToast'
    | 'LOGIN_STATUS'
    | 'logout'
    | 'errorFound'
    | 'websocketqeuedtask'
    | 'websocketnotification'
    | 'syncCreditsExhausted'
    | 'syncProductCreditsExhausted'
    | 'selectedProductsData'
    | 'expiryTime';
  state: any;
}
type errorType = 'fullPage' | 'modulePage' | '';
interface StateI {
  showToast: ObjectI;
  showID: number;
  LOGIN_STATUS?: {
    [name: string]: any;
    state: 'LOGIN' | 'LOGOUT';
  };
  errorFound: {
    location: string | null;
    showError: boolean;
    errorType?: errorType;
    message?: string | null;
  };
  websocketqeuedtask: {
    sync: number;
  };
  websocketnotification: {
    sync: number;
  };
  syncCreditsExhausted: {
    sync: boolean | number;
  };
  syncProductCreditsExhausted: {
    sync: boolean | number;
  };
  selectedProductsData: any;
  expiryTime: string;
}

interface ObjectI {
  [name: string]: any;
}

export const utility = (
  state: StateI = {
    showToast: {},
    showID: 1,
    errorFound: {
      location: null,
      showError: false,
      errorType: '',
      message: null,
    },
    websocketqeuedtask: {
      sync: 0,
    },
    websocketnotification: {
      sync: 0,
    },
    syncCreditsExhausted: {
      sync: -1,
    },
    syncProductCreditsExhausted: {
      sync: -1,
    },
    selectedProductsData: [],
    expiryTime: '',
  },
  action: UtilityActionI
): StateI => {
  switch (action.type) {
    case 'showToast':
      state['showToast'][state['showID']] = action.state;
      state['showID']++;
      return { ...state };
    case 'hideToast':
      delete state['showToast'][action.state.id];
      return { ...state };
    case 'logout':
      return {
        ...state,
        showToast: {},
        showID: 1,
        errorFound: {
          showError: false,
          location: null,
        },
      };
    case 'LOGIN_STATUS':
      return {
        ...state,
        LOGIN_STATUS: {
          ...action.state,
        },
      };
    case 'errorFound':
      return {
        ...state,
        errorFound: action.state,
      };
    case 'websocketqeuedtask':
      return {
        ...state,
        websocketqeuedtask: action.state,
      };
    case 'websocketnotification':
      return {
        ...state,
        websocketnotification: action.state,
      };
    case 'syncCreditsExhausted':
      return {
        ...state,
        syncCreditsExhausted: action.state,
      };
    case 'syncProductCreditsExhausted':
      return {
        ...state,
        syncProductCreditsExhausted: action.state,
      };
    case 'selectedProductsData':
      return { ...state, selectedProductsData: action.state };

    case 'expiryTime':
      return { ...state, expiryTime: action.state };
    default:
      return state;
  }
};
